import { Toast } from "bootstrap";
import { TranslateResult } from "vue-i18n";

export enum FlashKindEnum {
  SUCCESS = "success",
  ALERT = "alert",
  INFO = "info",
}
export function isFlashKindEnum(key: string): key is FlashKindEnum {
  return Object.values(FlashKindEnum).includes(key as FlashKindEnum);
}
const uniqueId = (length = 16) => {
  return parseInt(
    Math.ceil(Math.random() * Date.now())
      .toPrecision(length)
      .toString()
      .replace(".", "")
  );
};
export const flash = (
  kind: FlashKindEnum,
  message: string | TranslateResult
): void => {
  const id = uniqueId();
  const result = `<div id="toast-${id}" class="toast toast--fixed toast--${kind}"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="d-flex w-100 align-items-center">
        <div class="toast-body">
          ${message}
        </div>
        <button type="button" class="btn btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
    </div>`;

  const body = document.querySelector("body");
  if (body) {
    body.insertAdjacentHTML("beforeend", result);
    const toastElement = document.querySelector(`#toast-${id}`);
    if (toastElement) {
      const toast = new Toast(toastElement);
      toastElement.addEventListener("hidden.bs.toast", () => {
        toast.dispose();
        toastElement.remove();
      });
      toast.show();
    }
  }
};
