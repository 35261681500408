export const actionCableUrl = () =>
  (document.querySelector("meta[name=action-cable-url]") as HTMLMetaElement)
    .content;

export const fobizzHocuspocusWebsocketUrl = () =>
  (document.querySelector("meta[name=fobizz-hocuspocus]") as HTMLMetaElement)
    .content;

export const buildActionCableUrl = (): string => {
  const userStore = useUserStore();

  if (userStore.isVisitor) {
    return `${actionCableUrl()}?visitor_token=${userStore.user?.id}`;
  } else {
    return actionCableUrl();
  }
};

export const csrfToken = () =>
  (document.querySelector("meta[name=csrf-token]") as HTMLMetaElement).content;

export const getMetaTagContent = (name: string) => {
  const element = document.head.querySelector(
    `meta[name="${name}"]`
  ) as HTMLMetaElement;
  return element && element.content;
};
